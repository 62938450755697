<template>
  <div class="store-manage">
    <p>订单管理</p>

    <a-row type="flex" align="middle">
      <a-row :gutter="20">
        <a-col :span="24" :xl="10">
          <span>合同状态：</span>
          <a-checkable-tag :checked="form.contractStatus === null" :key="null" @change="checked(null, 'contractStatus')">全部</a-checkable-tag>
          <a-checkable-tag v-for="(i,index) in contractStatusList" :checked="form.contractStatus === index" :key="index" @change="checked(index, 'contractStatus')">
            {{ i }}
          </a-checkable-tag>
        </a-col>
        <a-col :span="24" :xl="14">
          <span>支付状态：</span>
          <a-checkable-tag :checked="form.paymentStatus === null" :key="null" @change="checked(null, 'paymentStatus')">全部</a-checkable-tag>
          <a-checkable-tag v-for="(i,index) in payStatusList" :checked="form.paymentStatus === index" :key="index" @change="checked(index, 'paymentStatus')">
            {{ i }}
          </a-checkable-tag>
        </a-col>
        <a-col :span="24" :xl="10">
          <span>物流状态：</span>
          <a-checkable-tag :checked="form.logisticsStatus === null" :key="null" @change="checked(null, 'logisticsStatus')">全部</a-checkable-tag>
          <a-checkable-tag v-for="(i,index) in logisticsStatusList" :checked="form.logisticsStatus === index" :key="index" @change="checked(index, 'logisticsStatus')">
            {{ i }}
          </a-checkable-tag>
        </a-col>
        <a-col :span="24" :xl="14">
          <span>开票状态：</span>
          <a-checkable-tag v-for="(i,index) in invoiceStatusList" :checked="form.invoiceStatusArray === i.status ? true : form.invoiceStatusArray && form.invoiceStatusArray.includes(i.status)" :key="index" @change="checked(i.status, 'invoiceStatusArray')">
            {{ i.name }}
          </a-checkable-tag>
        </a-col>
        <a-col :span="24" :xl="24">
            <span>风控预警：</span>
            <a-checkable-tag :checked="form.riskControlStatus === null" :key="null"
              @change="checked(null, 'riskControlStatus')">全部</a-checkable-tag>
            <a-checkable-tag v-for="(i,index) in riskControlStatusList" :checked="form.riskControlStatus === index"
              :key="index" @change="checked(index, 'riskControlStatus')">
              {{ i }}
            </a-checkable-tag>
          </a-col>
        <a-col :span="10">
          <span>订单日期：</span>
          <a-range-picker style="width: 260px" @change="onChange">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-col>
        <a-col :span="14">
          <span>搜索：</span>
          <a-input-search v-model="form.searchText" style="width: 350px" placeholder="请输入订单号、合同编号、买家名称模糊匹配" @search="form.pageNum = 1;getlist()"/>
        </a-col>
      </a-row>
      <a-col :span="24">
        <a-table :data-source="data" size="middle" :columns="stroeColumns" :rowKey="(record,index)=>{return index}" :loading="loading" @change="handleTableChange" 
          :pagination="{
          size: 'default',
          total: total,
          current: form.pageNum,
          pageSize: form.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showTotal: total => `共有 ${total} 条数据`,
          showQuickJumper: true,
          onChange: (pageNum) => form.pageNum = pageNum }">
          
          <template slot="isDefaultTitle">
            <div class="title-table">申报成本
              <a-tooltip>
                <template slot="title">
                  卖家填写的成本费用将做为最终税务自主申报成本记录
                </template>
                <a-icon class="title-icon" type="question-circle" theme="filled" />
              </a-tooltip>
            </div>
          </template>

          <template slot="finish" slot-scope="text,record">
            <div>合同：{{ record.contractPro }}</div>
            <div>金额：{{ record.paymentPro }}</div>
            <div>物流：{{ record.logisticsPro }}</div>
          </template>
          <template slot="riskControlStatus" slot-scope="text">
            <a-icon type="exclamation-circle" v-if="text==='INCOMPLETE'" style="color:red"/>
            <a-icon type="check-circle" v-else style="color:#8400ff"/>
          </template>
          <template slot="orderId" slot-scope="text">
            <router-link class="id-primary" :to="'/order-details?id=' + text">{{text}}</router-link>
          </template>
          <template slot="taxableAmount" slot-scope="text">
            <span>{{text.toFixed(2)}}元</span>
          </template>
          <template slot="invoiceStatus" slot-scope="text">
            <a-badge :status="text | color" :text="text | type"></a-badge>
          </template>
          <template slot="vatTaxAmount" slot-scope="text">
            <span>{{text.toFixed(2)}}元</span>
          </template>
          <template slot="quantity" slot-scope="text,record">
            <span>{{text}}{{record.unitsMeasure | unit}}</span>
          </template>
          <template slot="costAmount" slot-scope="text,record">
            <a-space v-if="!record.isEdit">
              <span v-if="text||text==0">
                {{ text}} 元
              </span>
              <span style="color:#999" v-else>
                输入申报金额
              </span>
              
              <a @click="record.isEdit = true" ><a-icon type="form" /></a>
            </a-space>
            <a-space v-else>
              <a-input-number id="inputNumber" v-model="record.editCostAmout" :precision="2" :placeholder="record.placeholder" style="width:120px"/>
              <a @click="record.isEdit = false;record.editCostAmout = text"><a-icon type="close" /></a>
              <a @click="onChangeQuantity(record.id,record.editCostAmout)"><a-icon type="check" /></a>
            </a-space>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { orderList, ordersCostdec } from "@/api/order";
import { type } from "@/utils/constArr";
const invoiceState = {
  WAIT_APPLY: '未申请',
  WAIT_AUDIT: '待审核',
  REFUSED: '已拒绝',
  REVIEWED: '审核通过',
  PROCESSING: '开票中',
  WAIT_INVOICE: '待开票',
  INVOICED: '已开票'
}
const color = {
  WAIT_APPLY: 'default',
  WAIT_AUDIT: 'warning',
  REFUSED: 'error',
  REVIEWED: 'success',
  PROCESSING: 'processing',
  WAIT_INVOICE: 'warning',
  INVOICED: 'success'
}
const stroeColumns = [
  {
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    title: '买家',
    dataIndex: 'purchaserCommercialName',
    align: 'left',
  },
  {
    title: '订单总额',
    dataIndex: 'contractAmount',
    scopedSlots: { customRender: 'contractAmount' },
    align: 'right',
  },
  {
    title: '金额（不含税）',
    dataIndex: 'taxableAmount',
    scopedSlots: { customRender: 'taxableAmount' },
    align: 'right',
  },
  {
    title: '增值税',
    dataIndex: 'vatTaxAmount',
    scopedSlots: { customRender: 'vatTaxAmount' },
    align: 'right',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    align: 'right',
  },
  {
    title: '完成度',
    scopedSlots: { customRender: 'finish' },
    align: 'right',
  },
  {
    title: '风控预警',
    dataIndex: 'riskControlStatus',
    scopedSlots: { customRender: 'riskControlStatus' },
    align: 'center',
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatus',
    scopedSlots: { customRender: 'invoiceStatus' }
  },
  {
    title: '下单日期',
    dataIndex: 'createdTime',
    align: 'left',
    width:'100px'
  },
  {
    // title: '申报成本',
    dataIndex: 'costAmount',
    align: 'center',
    slots: { title: 'isDefaultTitle' },
    scopedSlots: { customRender: 'costAmount' }
  }
]
const LIST_FORM_SELLER = {
  pageNum: 1,
  pageSize: 10,
  searchText: undefined,
  beginTime: undefined,
  endTime: undefined,
  invoiceStatusArray: null,
  logisticsStatus: null,
  contractStatus: null,
  paymentStatus: null,
  riskControlStatus: null
}
function resetQuery() {
  LIST_FORM_SELLER.pageNum = 1
  LIST_FORM_SELLER.pageSize = 10
  LIST_FORM_SELLER.searchText = undefined
  LIST_FORM_SELLER.beginTime = undefined
  LIST_FORM_SELLER.endTime = undefined
  LIST_FORM_SELLER.invoiceStatusArray = null
  LIST_FORM_SELLER.logisticsStatus = null
  LIST_FORM_SELLER.contractStatus = null
  LIST_FORM_SELLER.paymentStatus = null
  LIST_FORM_SELLER.riskControlStatus = null
}
export default {
  data() {
    return {
      data: [],
      loading: false,
      stroeColumns,
      contractStatusList: ['未签署', '已签署', '部分签署'],
      payStatusList: ['未支付', '已完成', '部分完成'],
      logisticsStatusList: ['未上传', '已完结', '部分完结'],
      riskControlStatusList: ['未完善', '已完善'],
      invoiceStatusList: [{
          name: '全部',
          status: null
        },
        {
          name: '等待申请开票',
          status: 'WAIT_APPLY'
        },
        {
          name: '待审核',
          status: 'WAIT_AUDIT'
        },
        {
          name: '已拒绝',
          status: 'REFUSED'
        },
        {
          name: '审核通过',
          status: 'REVIEWED'
        },
        {
          name: '开票中',
          status: 'PROCESSING'
        },
        {
          name: '等待开票',
          status: 'WAIT_INVOICE'
        },
        {
          name: '已开票',
          status: 'INVOICED'
        }],
      form: LIST_FORM_SELLER,
      total: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    // console.log(from);
    if (from.name == 'OrderDetails' && !from.meta.isReset) {
      // to.meta.isReset = true
      next()
      return
    }
    resetQuery()
    next()
  },
  mounted() {
    this.getList()
  },
  filters: {
      unit:(val)=>type[val],
      type: (val) => invoiceState[val],
      color: (val) => color[val]
  },
  methods: {
    getType(record){
      return record.invoiceStatus!='PROCESSING'&&record.invoiceStatus!='INVOICED'&&record.invoiceStatus!='REVIEWED'&&record.invoiceStatus!='WAIT_INVOICE'
    },
    // 编辑申报成本
    onChangeQuantity(orderId,costAmount){
      if(!costAmount && costAmount !== 0){
        this.$message.error('请输入申报成本！')
        return
      }
      ordersCostdec({
        orderId,
        costAmount
      }).then(res=>{
        if(res.code === 200){
          this.$message.success(res.message)
          this.getList()
        }else {
          this.$message.error(res.message)
        }
      })
    },
    getList() {
      this.loading = true
      orderList({...this.form, invoiceStatusArray: this.form.invoiceStatusArray && this.form.invoiceStatusArray.toString(), supplierId: this.$route.query.entityId}).then(res => {
        console.log(res)
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.list.map(item=>{
            item.isEdit = false
            item.editCostAmout = item.costAmount||item.costAmount==0?item.costAmount:null
            item.placeholder = `<=${(item.taxableAmount*0.96).toFixed(2)}` 
            item.costAmout = item.costAmount||item.costAmount==0?item.costAmount:null 
            return  item
          })
          this.total = res.data.total
        } else if (res.code === 405) {
          this.data = []
          this.form.pageNum = 1
          this.form.pageSize = 10
          this.total = 0
        }
      })
    },
    pickerChange(e,date) {
      this.form.beginTime = date[0] || null
      this.form.endTime = date[1] || null
      this.form.pageNum = 1
      this.getList()
    },
    handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getList()
    },
    checked (e, str) {
      if (str === 'invoiceStatusArray') {
        if (this.form[str]) {
          const index = this.form[str].findIndex(item => item === e)
          if (index > -1) {
            this.form[str].splice(index, 1)
            !this.form[str].length && (this.form[str] = null)
          } else {
            this.form[str] = e ? [...this.form[str], e] : null
          }
        } else {
          this.form[str] = [e]
        }
      } else {
        this.form[str] = e
      }
      this.form.pageNum = 1
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.store-manage {
  padding: 20px;
  background: #fff;
  font-size: 14px;
  /deep/ .ant-col {
    margin-bottom: 18px;
  }
  p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
    margin-bottom: 24px;
  }
  /deep/ .ant-table-wrapper{
    margin-top: 24px;
  }
}
/deep/ .ant-tag {
  font-size: 14px;
  line-height: 32px;
}
.row-handle {
  margin-bottom: 10px;
  margin-top: 24px;
}

.title-table {
  position: relative;
  .title-icon {
    position: absolute;
    right: -12px;
    top: -6px;
    color: #faad14;
  }
}
</style>